import React from 'react'
import { Headline } from '@liquid-design/liquid-design-react'
import LayoutLandingPage from '../layouts/LayoutLandingPage/LayoutLandingPage'
import Header from '../components/LandingPage/Header/Header'
import Section from '../components/LandingPage/Section/Section'
import Shapes from '../components/Shapes/Shapes'

export default function PageCallback() {
  return (
    <LayoutLandingPage compact className="h-screen flex flex-col">
      <Header noLogin />
      <Section className="flex items-center text-center h-full">
        <Headline type="H1" className="pb-16">
          Redirecting... Hang on.
        </Headline>
      </Section>
      <Shapes variant={2} />
    </LayoutLandingPage>
  )
}
